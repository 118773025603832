<template>
  <div id="app">
    <div class="navigation" data-app>
      <NavigationBarView />
    </div>
    <v-main class="pt-0">
      <HomeComponentView />
      <FeatureComponentView />
      <ProductsComponentView />
      <PartnerComponentView />
      <ContactComponentView />
    </v-main>
    <v-scale-transition>
      <v-btn
        style="z-index: 800"
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop">
        <v-icon style="color: #ad1c24">mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <FooterComponentView />
  </div>
</template>

<script>
  export default {
    name: "HomeComponent",

    components: {
      NavigationBarView: () => import("@/components/NavigationBar.vue"),
      HomeComponentView: () => import("@/components/HeroComponent.vue"),
      FeatureComponentView: () => import("@/components/FeatureComponent.vue"),
      ProductsComponentView: () => import("@/components/ProductsComponent.vue"),
      PartnerComponentView: () => import("@/components/PartnerComponent.vue"),
      FooterComponentView: () => import("@/components/FooterComponent.vue"),
      ContactComponentView: () => import("@/components/ContactComponent.vue"),
    },
  };
</script>
